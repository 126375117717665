// Library
import React from "react";
import {Redirect, Switch, Route} from "react-router-dom";
// Pages
import HomePage from "../Pages/HomePage";
import OrderPage from "../Pages/OrderPage";
import OrderByStylePage from "../Pages/OrderByStyle";
import CartPage from '../Pages/CartPage';
import CheckoutPage from "../Pages/Checkout";
import AllOrdersPage from "../Pages/AllOrders";
import ChangePassword from '../Pages/ChangePassword';
import CartStorageManage from "../Pages/CartStorage/Manage";
// import OrderAcceptancePage from "../Pages/OrderAcceptancePage";
import OrderAcceptancePage from "../Pages/OrderAcceptancePageV2";
import ReportPage from '../Pages/Report';
// User Management
import ManageUser from "../Pages/UserMangement/Manage";
import AddUser from "../Pages/UserMangement/Add";
import ChangeUserPassword from "../Pages/UserMangement/ChangePassword";
import UpdateUser from "../Pages/UserMangement/Update";
// Support
import SupportPage from '.././Pages/Support';
// Order Reports
import OrderReports from "../Pages/OrderReports";
import OrderReportsByFilters from '../Pages/OrderReportsByFilters';
// Credit App
import CreditAppPage from '.././Pages/CreditAppPage';

export default function BasePage() {
    return (

            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/home"/>
                }

                {/*Dashboard*/}
                <Route path={"/home"} component={HomePage}/>

                {/* Order page*/}
                <Route path={"/change-password"} component={ChangePassword}/>
                <Route path={"/order/style"} component={OrderByStylePage}/>
                <Route path={"/order"} component={OrderPage}/>
                <Route path={"/cart-storage"} component={CartStorageManage}/>
                <Route path={"/credit-app"} component={CreditAppPage}/>
                <Route path={"/cart"} component={CartPage}/>
                <Route path={"/checkout"} component={CheckoutPage}/>
                <Route path={"/support"} component={SupportPage}/>
                <Route path={"/all-orders"} component={AllOrdersPage}/>
                <Route path={"/order-acceptance/:uuid"} component={OrderAcceptancePage}/>
                <Route path={"/report/:season"} component={ReportPage}/>
                <Route path={"/user-management/manage"} component={ManageUser}/>
                <Route path={"/user-management/add"} component={AddUser}/>
                <Route path={"/user-management/change-password/:id"} component={ChangeUserPassword}/>
                <Route path={"/user-management/update/:id"} component={UpdateUser}/>
                <Route path={"/order-reports"} component={OrderReports}/>
                <Route path={"/order-reports-by-dates-and-reps"} component={OrderReportsByFilters}/>

                <Redirect to="/404"/>
            </Switch>
    );
}
