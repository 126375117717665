// Library
import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import LazyImage from "../../Components/LazyImage";
import {Table} from 'react-bootstrap';
import _ from 'lodash';
import {faCut, faPenNib} from "@fortawesome/free-solid-svg-icons";
// Component
import PlusMinusInput from "../../Components/PlusMinusInput";
import FontIcon from "../../Components/FontIcon";
// Logger
import Logger from "../../Utils/Logger";
// Helper
import {
    singleCartIdGenerator,
    customDateFormatter,
    priceViewer,
    checkExceedsMaxQty,
    areDatesEqual, checkExceedsMaxQtyInWholeCart
} from '../../Utils/Helpers';
// Redux Actions
import {addToCart, changeDeliveryDateInCart} from '../../Store/Cart/action';
import FilterForItems from "../../Components/FilterForItems";

const ProductViewer = (props) => {
    const {sizes, sizesAccessory, product} = props;

    const [isOpen, setIsOpen] = useState(null);
    const [renderStyleTable, setRenderStyleTable] = useState(true);
    const [renderAccessoryTable, setRenderAccessoryTable] = useState(true);

    const cartState = useSelector(state => state.cart);
    const userState = useSelector(state => state.user);
    const orderState = useSelector(state => state.order);
    const {themeMode} = useSelector(state => state.theme);
    const dispatch = useDispatch();

    // Logger.log("CART: ", cartState.cartDetails);

    useEffect(() => {
        const nonAccessoryFinder = _.filter(product, p => !p.accessory);
        const accessoryFinder = _.filter(product, p => p.accessory);

        // Logger.log("STYLE FINDER: ", nonAccessoryFinder);
        // Logger.log("ACCESSORY FINDER: ", accessoryFinder);

        if (nonAccessoryFinder.length && !accessoryFinder.length) {
            setRenderStyleTable(true);
            setRenderAccessoryTable(false);
        }

        if (!nonAccessoryFinder.length && accessoryFinder.length) {
            setRenderStyleTable(false);
            setRenderAccessoryTable(true);
        }

    }, [orderState])

    const handleShowDialog = (id) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, F
        setIsOpen(id ? id : null);
    };

    const incrementer = (id, product, size, sizeNumber) => {
        // Logger.log("Incremented: " + id);
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        dispatch(addToCart(id, product, size, sizeNumber));
    }

    const decrementer = (id, product, size, sizeNumber) => {
        // Logger.log("Decremented: " + id);
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        dispatch(addToCart(id, product, size, sizeNumber, 2));
    }
    const inputChanger = (id, product, size, sizeNumber, qty) => {
        // Logger.log("Product: ", product);
        // Logger.log("Size: " + size);
        if (qty) {
            dispatch(addToCart(id, product, size, sizeNumber, 3, qty));
        }
    }

    const showTotal = (product) => {
        const cartDetailParsed = cartState.cartDetailRevamped;
        const checkProductInCartDetailParsed = _.find(cartDetailParsed, p => p.id === product.id);
        // const border = themeMode === 'light' ? "2px solid #000" : "2px solid #fff";
        return (
            <td className={"total-td"} style={{textAlign: "center", lineHeight: "90px"}}>
                <span>{checkProductInCartDetailParsed ? checkProductInCartDetailParsed.qty["totalQuantity"] : 0}</span>
            </td>
        )
    }

    const showAvailableStatus = (product) => {

        const itemStatus = product.itemAvailability;
        if (itemStatus.length) {
            let deliveryDateDisplay = itemStatus[0].deliveryDateDisplay;
            // Logger.log("=== Available Status Display : " , itemStatus);
            const statusHavingAltQty = _.find(itemStatus, p => p.hasOwnProperty("alternativeQtyAndDeliveryDate") &&
                p.alternativeQtyAndDeliveryDate != null);
            // Logger.log("=== Alternative QTY : " , statusHavingAltQty);

            const cartDetailParsed = cartState.cartDetailRevamped;
            const checkProductInCartDetailParsed = _.find(cartDetailParsed, p => p.id === product.id);
            // Logger.log("=== Current Item In Cart : " , checkProductInCartDetailParsed);
            if(statusHavingAltQty && checkExceedsMaxQty(itemStatus, checkProductInCartDetailParsed)) {
                deliveryDateDisplay = statusHavingAltQty.alternativeQtyAndDeliveryDate.wipDateDisplay;

            }


            return (
                <>
                    <td className={"status-td"} style={{textAlign: "center",background : product.carryOver ?  window.config.CARRY_OVER_ITEM_COLOR  : "transparent"}}>
                        <div>
                            {deliveryDateDisplay}
                        </div>
                        <div style={{textAlign: "center", fontSize: "15px", marginTop: "-4px"}}>
                            <strong>Availability</strong>
                        </div>
                    </td>
                </>
            )
        }

        return (
            <td className={"status-td"} style={{textAlign: "center"}}>
                N/A
            </td>
        );
    }

    const blackDiv = () => {

        return (
            <>
                <div style={{
                    width: "100px",
                    backgroundColor: themeMode === 'light' ? 'rgb(108 117 125)' : 'rgb(255 255 255)',
                    height: "35px"
                }}/>

            </>
        )
    }

    const productRendererWithImage = (product) => {
        const imageId = "pd-image-" + product.id;
        const imageUrl = window.config.PRODUCT_IMAGE_URL + product.fullStyle + ".jpg";
        return (
            <>
                <td className={themeMode === 'light' ? 'bg-light product-td' : 'bg-dark product-td'}>
                    <strong className={themeMode === 'light' ? 'text-dark' : 'text-light'}>{product.fullStyle + " | " + product.description} {product.embroideryAvailable ? (
                        <FontIcon className={"text-success"} icon={faCut}/>
                    ) : null}
                    </strong>
                    <div id={imageId} className={"pd-img"}>
                        <LazyImage
                            clicker={() => handleShowDialog(imageUrl)}
                            src={window.config.PRODUCT_IMAGE_URL + product.fullStyle + ".jpg"}
                            height={80}
                            width={80}
                        />
                    </div>

                </td>

            </>
        )
    }

    const renderer = (product, size, key, availabilityShow = false) => {
        const cartDetail = cartState.cartDetailRevamped;
        const cartUniqueId = singleCartIdGenerator(size.sizeName, product.id, userState.userData.id);
        const checkProductInCartDetail = _.find(cartDetail, p => p.id === product.id);
        // Getting product Viewer Form item
        const itemStatus = product.itemAvailability;
        const currentSizeStatus = _.find(itemStatus, p => p.sizeNumber === size.sizeNumber);
        const checkDisabledInConfig = _.find(window.config.DISABLED_SIZES , p => p.style === product.fullStyle);
        let disabledSizesInConfig = [];
        const alternativeQtyAndDeliveryDate = currentSizeStatus && currentSizeStatus.hasOwnProperty("alternativeQtyAndDeliveryDate") ? currentSizeStatus.alternativeQtyAndDeliveryDate: null;
        // Logger.log("alternativeQtyAndDeliveryDate", alternativeQtyAndDeliveryDate);
        if(checkDisabledInConfig) {
            disabledSizesInConfig = checkDisabledInConfig.sizes;
        }
        // Logger.log("Product Full Style : ", product.fullStyle);
        // Logger.log("Size in product Viewer: ", size.sizeNumber);
        // Logger.log("Size in product Viewer Name: ", size.sizeName);
        // Logger.log("Size in product Viewer Display: ", size.displaySizeName);
        // Logger.log("Check In Cart Detail? ", checkProductInCartDetail);
        let checkInCartDetail = false;
        if (checkProductInCartDetail) {
            if (checkProductInCartDetail.qty[size.sizeName] > 0) {
                checkInCartDetail = checkProductInCartDetail.qty[size.sizeName];
            }

        }
        let show = false;
        _.forIn(product.sizes, (sizeKey, sizeIndex) => {
            if (sizeKey === size.sizeName || sizeKey === size.displaySizeName) {
                show = true;
                if(disabledSizesInConfig.length > 0 && _.indexOf(disabledSizesInConfig, sizeKey) >= 0) {
                    show = false;
                }
            }
        });
        let inputQty = currentSizeStatus && currentSizeStatus.view.available ? currentSizeStatus.view.available : 0;
        if(alternativeQtyAndDeliveryDate) {
            inputQty = inputQty + " + (" + alternativeQtyAndDeliveryDate.wipQty + ")"
        }

        return (
            <>
                {!availabilityShow ? (
                    <td
                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                        style={{
                        width: "160px",
                        padding: "17px 28px 0px",
                        paddingBottom: 0,
                        background: currentSizeStatus && currentSizeStatus.hasOwnProperty("view") ? currentSizeStatus.view.cellColor : "transparent"
                    }} key={key}
                    >
                        {show ? (
                            <>
                                <PlusMinusInput incrementer={incrementer}
                                                decrementer={decrementer}
                                                product={product}
                                                size={size.sizeName}
                                                sizeNumber={size.sizeNumber}
                                                value={checkInCartDetail ? checkInCartDetail : 0}
                                                id={cartUniqueId}
                                                name={cartUniqueId}
                                                inputChanger={inputChanger}
                                                onBlur={inputChanger}
                                />

                                <div key={key} style={{textAlign: "center", marginTop: "50px"}}>
                                    {inputQty}
                                </div>

                            </>
                        ) : (
                            <>
                                {blackDiv()}
                            </>
                        )}

                    </td>
                ) : (
                    <td key={key} style={{textAlign: "center"}} className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                        {inputQty}
                    </td>
                )}

            </>
        )
    }

    const rowStyleRender = (product) => {

        // if (product.hasOwnProperty("availableStatus") && product.availableStatus) {
        //     const {availableStatus} = product;
        //     const nextWip = availableStatus.hasOwnProperty("nextwipqty") && availableStatus.nextwipqty ? availableStatus.nextwipqty : false;
        //     const onhand = parseInt(availableStatus.onhand);
        //     const nextwipdate = availableStatus.hasOwnProperty("nextwipdate") && availableStatus.nextwipdate ? `(${customDateFormatter(availableStatus.nextwipdate, 5).displayForm})` : "";
        //
        //     if (!nextWip
        //         && onhand <= 0
        //         && !product.deliveryGreaterThanTwoMonths
        //         && !nextwipdate
        //     ) {
        //         return {
        //             background: window.config.NOT_AVAILABLE_ITEM_ROW_COLOR
        //         }
        //     }
        // }

        return {}
    }

    const colorRenderer = (product) => {
        return (
            <td style={{textAlign: "center"}} className={themeMode === 'light' ? 'bg-light' : 'bg-dark'}><strong
                style={{lineHeight: "90px", textAlign: "center"}} className={themeMode === 'light' ? 'text-dark' : 'text-light'}>{product.colorCode}</strong></td>
        )
    }

    const priceRenderer = (product) => {
        return (
            <td style={{textAlign: "center"}} className={themeMode === 'light' ? 'bg-light' : 'bg-dark'}>
                <strong style={{lineHeight: "90px", textAlign: "center"}} className={themeMode === 'light' ? 'text-dark' : 'text-light'}>
                    ${product.cost}
                </strong>
            </td>
        )
    }

    const getVoidHeader = (content) => {
        return (
            <th style={{textAlign: "center", fontSize: "15px"}}>{content}</th>
        )
    }
    const getVoidBody = () => {
        return (
            <td style={{width: "160px", padding: "17px 29px 0px", background: "transparent"}}>{blackDiv()}</td>
        )
    }


    const renderBlackDivsFoAccessories = (sizes, isHead = false) => {
        // Logger.log("SIZES length: ", sizes);
        const returner = [];
        if (sizes && sizes.length) {

            for (let i = 0; i < sizes.length - sizesAccessory.length; i++) {
                if (!isHead) {
                    returner.push(getVoidBody());
                } else {
                    returner.push(getVoidHeader("-"));
                }

            }


            return returner;
        } else {
            return null;
        }
    }

    const renderSizeHeader = () => {

        const uniqueSizes = _.uniqBy(sizes, p => p.sizeNumber);

        return _.map(uniqueSizes, (singleSize, sizeKey) => (
            <th style={{textAlign: "center", fontSize: "15px"}}
                className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                scope={"row"}>{singleSize.displaySizeName}</th>
        ));
    }

    return (
        <>
            <div className={"mt-3 tableFixBody"}>

                {isOpen && (
                    <dialog
                        className="dialog"
                        style={{position: 'absolute', zIndex: 99999}}
                        open
                        onClick={() => handleShowDialog(false)}
                    >
                        <img
                            className="image"
                            src={isOpen}
                            onClick={() => handleShowDialog(false)}
                            alt="N/A"
                        />
                    </dialog>
                )}
                {renderStyleTable ? (
                    <>
                        <h4 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}
                            style={{fontWeight: "700"}}>All
                            Sizes</h4>
                        <FilterForItems/>
                        <Table size={"sm"} bordered
                               className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                               style={{fontSize: "13px"}}>
                            <tbody>
                            {product ? (
                                <>
                                    {_.map(product, (singleProduct, key) => (
                                        <>

                                            {key === 0 ? (
                                                <>
                                                    <tr>
                                                        <th scope={"row"} style={{minWidth: "400px", fontSize: "15px"}}
                                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                                                            >Product
                                                        </th>
                                                        <th scope={"row"}
                                                            style={{
                                                                width: "50px",
                                                                fontSize: "15px",
                                                                textAlign: "center"
                                                            }}
                                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                                            Color
                                                        </th>
                                                        <th scope={"row"}
                                                            style={{
                                                                width: "50px",
                                                                fontSize: "15px",
                                                                textAlign: "center"
                                                            }}
                                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                                            Price
                                                        </th>
                                                        <th scope={"row"}
                                                            style={{
                                                                width: "100px",
                                                                fontSize: "15px",
                                                                textAlign: "center"
                                                            }}
                                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Delivery
                                                            Date
                                                        </th>

                                                        {
                                                            renderSizeHeader(singleProduct)
                                                        }
                                                        <th scope={"row"}
                                                            className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                                                            style={{
                                                                textAlign: "center",
                                                                fontSize: "15px",
                                                                width: "70px"
                                                            }}>Total
                                                        </th>
                                                    </tr>
                                                </>
                                            ) : null}
                                            {!singleProduct.accessory ? (
                                                <>
                                                    <tr style={rowStyleRender(singleProduct)}>
                                                        <>{productRendererWithImage(singleProduct)}</>
                                                        <>{colorRenderer(singleProduct)}</>
                                                        <>{priceRenderer(singleProduct)}</>
                                                        <>{showAvailableStatus(singleProduct)}</>
                                                        {_.map(_.uniqBy(sizes, p => p.sizeNumber), (singleSize, sizeKey) => (
                                                            <>
                                                                {renderer(singleProduct, singleSize, sizeKey)}
                                                            </>
                                                        ))}
                                                        <>{showTotal(singleProduct)}</>


                                                    </tr>
                                                </>
                                            ) : null}

                                        </>
                                    ))}
                                </>
                            ) : null}

                            </tbody>
                        </Table>
                    </>
                ) : null}
                {renderAccessoryTable ? (
                    <>
                        {sizesAccessory && sizesAccessory.length ? (
                            <>
                                <hr style={{borderTop: "5px solid red"}}/>
                                <h4 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}
                                    style={{fontWeight: "700"}}>Accessories</h4>

                                <hr style={{borderTop: "5px solid red"}}/>
                                <Table size={"sm"} bordered
                                       className={themeMode === 'light' ? 'mt-3 text-dark' : 'mt-3 text-light'}
                                       style={{fontSize: "13px"}}>
                                    <tbody>
                                    {product ? (
                                        <>
                                            {_.map(product, (singleProduct, key) => (
                                                <>

                                                    {key === 0 ? (
                                                        <>
                                                            <tr>
                                                                <th scope={"row"}
                                                                    style={{minWidth: "400px", fontSize: "15px"}}
                                                                    className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Product
                                                                </th>
                                                                <th scope={"row"}
                                                                    style={{
                                                                        width: "50px",
                                                                        fontSize: "15px",
                                                                        textAlign: "center"
                                                                    }}
                                                                    className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                                                    Color
                                                                </th>
                                                                <th scope={"row"}
                                                                    style={{
                                                                        width: "50px",
                                                                        fontSize: "15px",
                                                                        textAlign: "center"
                                                                    }}
                                                                    className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>
                                                                    Price
                                                                </th>
                                                                <th scope={"row"}
                                                                    style={{
                                                                        width: "100px",
                                                                        fontSize: "15px",
                                                                        textAlign: "center"
                                                                    }}
                                                                    className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}>Delivery
                                                                    Date
                                                                </th>

                                                                {_.map(sizesAccessory, (singleSize, sizeKey) => (
                                                                    <th style={{textAlign: "center", fontSize: "15px"}}
                                                                        className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                                                                        scope={"row"}>{singleSize.sizeName}</th>
                                                                ))}
                                                                {/*{renderBlackDivsFoAccessories(sizes, true)}*/}
                                                                <th scope={"row"}
                                                                    className={themeMode === 'light' ? 'bg-light text-dark' : 'bg-dark text-light'}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontSize: "15px",
                                                                        width: "70px"
                                                                    }}>Total
                                                                </th>
                                                            </tr>
                                                        </>
                                                    ) : null}
                                                    {singleProduct.accessory ? (
                                                        <>
                                                            <tr style={rowStyleRender(singleProduct)}>
                                                                <>{productRendererWithImage(singleProduct)}</>
                                                                <>{colorRenderer(singleProduct)}</>
                                                                <>{priceRenderer(singleProduct)}</>
                                                                <>{showAvailableStatus(singleProduct)}</>
                                                                {_.map(sizesAccessory, (singleSize, sizeKey) => (
                                                                    <>
                                                                        {renderer(singleProduct, singleSize, sizeKey)}
                                                                    </>
                                                                ))}

                                                                {/*{renderBlackDivsFoAccessories(sizes)}*/}
                                                                <>{showTotal(singleProduct)}</>


                                                            </tr>
                                                        </>
                                                    ) : null}

                                                </>
                                            ))}
                                        </>
                                    ) : null}

                                    </tbody>
                                </Table>
                            </>
                        ) : null}
                    </>
                ) : null}


                {/*<div className={"float-right"}>*/}
                {/*    <Button style={{color: Color.white, float: "right"}} clicker={() => history.push("/cart")} size={"large"}>Proceed</Button>*/}
                {/*</div>*/}
            </div>
        </>
    )
}
export default connect(null, {addToCart})(ProductViewer);
