// Library
import React, {useEffect,useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {ErrorMessage, Form, Formik} from 'formik';
import * as yup from 'yup';
// Helpers
import {toaster} from '../../Utils/Helpers';
// Redux Actions
import {changePassword} from "../../Store/User/actions";
// Container
import PageContainer from "../../Containers/PageContainer";
// Components
import InputBox from "../../Components/Input";
import Loader from "../../Components/Loader";
import Button from "../../Components/Button";
// Config
import Colors from "../../Config/Colors";
// Logger
import Logger from "../../Utils/Logger";

// Validations
const validationSchema = yup.object().shape({
    password: yup.string().required("Password is Required").min(6, "Password is too short"),
});
// Initial Values
const initialDefaultValues = {
    password: "",

}

const AddUser = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const userState = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(initialDefaultValues);

    useEffect(() => {
        setInitialValues(initialDefaultValues);
    }, []);

    // Input Changer
    const inputChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index: ", index);
        formik.setFieldValue(fieldName, value);


        // Logger.log("Formik", formik);
    }


    const onSubmit = (values, {setStatus, setSubmitting}) => {


        Logger.log("VALUES: ", values);
        values.id = userState.userData.id;
        setLoading(true);
        setSubmitting(true);
        setStatus(false);
        let sev = "";
        let message = "";

        dispatch(changePassword(values, (err) => {
            if (!err) {
                message = "Successfully Changed The Password";
                sev = "success";
                history.push("/");

            }
            setLoading(false);
            setSubmitting(false);
            toaster(message, sev);
        }));
    }


    return (
        <PageContainer>
            <h3 className={"text-center"}>Change User Password</h3>
            <hr/>
            <Row>
                {userState.processing ? (
                    <Col sm={{span: 2, offset: 5}}>
                        <Loader/>
                    </Col>
                ) : (
                    <Col>
                        <div className={"text-center"}>
                            Change Password for "{userState.userData.name}"
                        </div>

                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formikProps) => (
                                <Form>
                                    <Row className={"mt-4"}>
                                        <Col sm={{span: 4, offset: 4}}>
                                            <InputBox
                                                margin={'dense'}
                                                fullWidth
                                                id="password"
                                                type={"password"}
                                                name={"password"}
                                                label="Password"
                                                helperText={"Enter Password"}
                                                variant="filled"
                                                changer={(e) => inputChanger(e.target.value, formikProps, `password`)}

                                            />
                                            <div>
                                                <ErrorMessage component={"div"}
                                                              className={"text-danger text-center"}
                                                              name={`password`}/>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{span: 2, offset: 5}}>
                                            {loading ? (
                                                <Loader/>
                                            ) : (
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    style={{color: Colors.white, marginTop: "30px"}}
                                                    disabled={loading}
                                                >
                                                    Change Password
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                )}

            </Row>
        </PageContainer>
    )
}

export default AddUser;
