// Library
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import {ErrorMessage, Form, Formik} from 'formik';
import * as yup from 'yup';
// Helpers
import {toaster} from '../../../Utils/Helpers';
// Redux Actions
import {getUserById, getAllSalesman, updateUser, getAllUsers} from "../../../Store/User/actions";
import {getAllRoles} from "../../../Store/Roles/actions";
// Container
import PageContainer from "../../../Containers/PageContainer";
// Components
import Loader from "../../../Components/Loader";
import Button from "../../../Components/Button";
// Config
import Colors from "../../../Config/Colors";
// Logger
import Logger from "../../../Utils/Logger";
import {getAllDivisions} from "../../../Store/Order/action";
import Select from "../../../Components/Select";
import {FormHelperText} from "@mui/material";
import _ from "lodash";
import InputBox from "../../../Components/Input";
// Validations
const validationSchema = yup.object().shape({
    salesmanId: yup.string().required("Sales Rep Selection is Required"),
});
// Initial Values
const initialDefaultValues = {
    salesmanId: "",
    userDivisions: [],
    parentRoleId: "",
    roleId: "",
    email: "",
    parentRoles: []

}
// Some Internal Constants
const REP = "rep";
const CSR = "csr";
const ADMIN = "admin";

const UpdateUser = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();

    const userState = useSelector(state => state.user);
    const orderState = useSelector(state => state.order);
    const roleState = useSelector(state => state.role);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(initialDefaultValues);
    const [selectedRole, setSelectedRole] = useState(ADMIN);
    const [showParentRep, setShowParentRep] = useState(false);

    const allCsrs = userState.allUsersForAdding ? userState.allUsersForAdding.csr : [];
    const allReps = userState.allUsersForAdding ? userState.allUsersForAdding.reps : [];

    Logger.log("SHOW PARENT REP: ", showParentRep);

    useEffect(() => {
        dispatch(getAllSalesman());
        dispatch(getAllDivisions());
        dispatch(getAllRoles());
        dispatch(getAllUsers());
        dispatch(getUserById(id, (err) => {
            if (err) {
                toaster("User Not Found", "danger");
                history.goBack();
            }
        }))


    }, []);
    // Checking For Admin
    useEffect(() => {
        if (!userState.isAdmin) {
            toaster("You are not allowed to see this page", "danger");
            history.push("/");
        }

    }, []);

    useEffect(() => {
        if (userState.transactionalUser) {
            formMaker();
        }
    }, [userState]);

    const formMaker = () => {
        Logger.log("USER STATE", userState);


        if (userState.transactionalUser.roleId === window.config.REP_ROLE_ID) {
            setSelectedRole(REP);
            setInitialValues(userState.transactionalUser);
        } else if (userState.transactionalUser.roleId === window.config.CSR_ROLE_ID) {
            setSelectedRole(CSR);
            const allAssignedReps = _.filter(userState.allUsers, p => p.parentRoleId === userState.transactionalUser.id);
            Logger.log("ALL ASSIGNED REPS: ", allAssignedReps);
            const userToBeAssigned = {...userState.transactionalUser};
            userToBeAssigned.parentRoles = allAssignedReps;
            setInitialValues(userToBeAssigned);
        } else {
            setSelectedRole(ADMIN);
            setInitialValues(userState.transactionalUser);
        }
    }

    // Input Changer
    const inputChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index: ", index);
        formik.setFieldValue(fieldName, value);


        // Logger.log("Formik", formik);
    }

    // Drop Down Changer
    const dropDownChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index: ", index);
        formik.setFieldValue(fieldName, index ? value[index] : value.id);
        if (fieldName === "roleId") {
            if (value.id === window.config.REP_ROLE_ID) {
                setShowParentRep(true);
            } else {
                setShowParentRep(false);
            }
        }

        // Logger.log("Formik", formik);
    }
    // Drop Down Changer
    const multiDropDownChanger = (value, formik, fieldName, index) => {
        Logger.log("VALUE: ", value);
        Logger.log("FORMIK: ", formik);
        Logger.log("Field Name: ", fieldName);
        Logger.log("index: ", index);
        formik.setFieldValue(fieldName, value);


        Logger.log("Formik", formik);
    }


    const onSubmit = (values, {setStatus, setSubmitting}) => {


        Logger.log("VALUES: ", values);
        setLoading(true);
        setSubmitting(true);
        setStatus(false);
        let sev = "";
        let message = "";

        const userDivisions = [];
        if (values.userDivisions.length) {
            _.map(values.userDivisions, (division, index) => {
                userDivisions.push(division.id);
            })
        }

        values.divisions = userDivisions;

        if (values.parentRoleId === ADMIN || values.parentRoleId === CSR) {
            values.parentRoleId = "";
        }

        const parentRoles = [];

        if (values.hasOwnProperty("parentRoles") && Array.isArray(values.parentRoles) && values.parentRoles.length) {
            _.map(values.parentRoles, (roles, index) => {
                parentRoles.push(roles.id);
            })
        }

        values.parentRoles = parentRoles;

        dispatch(updateUser(values, (err) => {
            if (err) {
                Logger.log("Errors: ", err);

                message = "There are errors in updating the user";
                sev = "danger";
                setLoading(false);
                setSubmitting(false);
            } else {
                message = "Successfully Updated the User";
                sev = "success";
                setTimeout(() => {
                    setLoading(false);
                    setSubmitting(false);
                    history.goBack();
                }, 6000);

            }

            toaster(message, sev);
        }));
    }

    return (
        <PageContainer>
            <h3 className={"text-center"}>Update User</h3>
            <hr/>
            {userState.processing ? (
                <>
                    <Col sm={{span: 2, offset: 5}}>
                        <Loader/>
                    </Col>
                </>
            ) : (
                <>
                    <Row>
                        <Col>
                            <div className={"text-center"}>
                                Update user data for
                                "{userState.transactionalUser ? userState.transactionalUser.name : null}"
                            </div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {(formikProps) => (
                                    <Form>

                                        <Row className={"mt-4"}>
                                            <Col sm={{span: 4, offset: 4}}>
                                                <InputBox
                                                    value={formikProps.values.email}
                                                    margin={'dense'}
                                                    fullWidth
                                                    id="email"
                                                    name={"email"}
                                                    label="Email"
                                                    helperText={"Enter Email"}
                                                    variant="filled"
                                                    changer={(e) => inputChanger(e.target.value, formikProps, `email`)}

                                                />
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`email`}/>
                                                </div>
                                            </Col>
                                            <Col sm={{span: 4, offset: 4}}>
                                                <Select
                                                    isDisabled={true}
                                                    data={userState.allSalesman ? userState.allSalesman : []}
                                                    value={formikProps.values.salesmanId}
                                                    placeholder={"Select Sales Rep"}
                                                    changer={(value) => dropDownChanger(value, formikProps, `salesmanId`)}/>
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`salesmanId`}/>
                                                </div>
                                                <FormHelperText>Sales Rep</FormHelperText>
                                            </Col>
                                            <Col sm={{span: 4, offset: 4}}>
                                                <Select
                                                    isDisabled={true}
                                                    data={roleState.allRoles}
                                                    value={formikProps.values.roleId}
                                                    placeholder={"Role"}
                                                    changer={(value) => dropDownChanger(value, formikProps, `roleId`)}/>
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`roleId`}/>
                                                </div>
                                                <FormHelperText>Role</FormHelperText>
                                            </Col>

                                            <Col sm={{span: 4, offset: 4}}>
                                                <Select
                                                    data={orderState.division ? orderState.division : []}
                                                    value={formikProps.values.userDivisions}
                                                    placeholder={"Please Select Divisions"}
                                                    multi={true}
                                                    changer={(value) => multiDropDownChanger(value, formikProps, `userDivisions`)}/>
                                                <div>
                                                    <ErrorMessage component={"div"}
                                                                  className={"text-danger text-center"}
                                                                  name={`userDivisions`}/>
                                                </div>
                                                <FormHelperText>Select Divisions</FormHelperText>
                                            </Col>

                                            {selectedRole === CSR ? (
                                                <Col sm={{span: 4, offset: 4}}>
                                                    <Select
                                                        data={allReps}
                                                        value={formikProps.values.parentRoles}
                                                        multi={true}
                                                        placeholder={"Select Sales REP Supported"}
                                                        changer={(value) => multiDropDownChanger(value, formikProps, `parentRoles`)}/>
                                                    <div>
                                                        <ErrorMessage component={"div"}
                                                                      className={"text-danger text-center"}
                                                                      name={`parentRoles`}/>
                                                    </div>
                                                    <FormHelperText>Select Sales REP Supported</FormHelperText>
                                                </Col>


                                            ) : null}



                                            {selectedRole === REP ? (
                                                <>
                                                    <Col sm={{span: 4, offset: 4}}>
                                                        <Select
                                                            data={allCsrs}
                                                            value={formikProps.values.parentRoleId}
                                                            placeholder={"Assign CSR"}
                                                            changer={(value) => dropDownChanger(value, formikProps, `parentRoleId`)}/>
                                                        <div>
                                                            <ErrorMessage component={"div"}
                                                                          className={"text-danger text-center"}
                                                                          name={`parentRoleId`}/>
                                                        </div>
                                                        <FormHelperText>Assign CSR</FormHelperText>
                                                    </Col>
                                                </>
                                            ) : null}
                                        </Row>
                                        <Row>
                                            <Col sm={{span: 2, offset: 5}}>
                                                {loading ? (
                                                    <Loader/>
                                                ) : (
                                                    <Button
                                                        fullWidth
                                                        type="submit"
                                                        style={{color: Colors.white, marginTop: "30px"}}
                                                        disabled={loading}
                                                    >
                                                        Update User
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </>
            )}
        </PageContainer>
    )
}

export default UpdateUser;
