// Library
import _ from 'lodash';
// Logger
import Logger from "../../Utils/Logger";
// Constants
import {FILTER_PRICE_HIGH_TO_LOW, SIZES_INDEXES, SIZES_LENGTH} from "../../Utils/CommonConstants";
// Helpers
import {customDateFormatter, dateDifferenceInWeeks, getNewDate} from "../../Utils/Helpers";
import {addMonths} from "date-fns";


export const parseItemsForSelectMenu = (allItems, itemStatus, filter = null) => {
    Logger.log("All Items For parsing: ", allItems);
    Logger.log("All Items Status: ", itemStatus);
    const returner = [];

    const sizes = extractSizesFromAllItemsList(allItems);
    const sizes_accessory = extractSizesFromAllItemsList(allItems, true);

    _.map(allItems, (item) => {

        const dd = dateDifferenceInWeeks(item.deliveryDate, new Date().toDateString());
        const gracePeriodForDelivery = window.config.GRACE_TIME_DELIVERY_PERIOD;

        Logger.log("== DateDifference", dd);

        const currentItemStatus = _.find(itemStatus, p => p.style === item.fullStyle);
        const nextWip = currentItemStatus ? currentItemStatus.hasOwnProperty("nextwipqty") && currentItemStatus.nextwipqty ? currentItemStatus.nextwipqty : false : false;
        const onhand = currentItemStatus ? parseInt(currentItemStatus.onhand) : 0;
        // const nextwipdate = currentItemStatus.hasOwnProperty("nextwipdate") && currentItemStatus.nextwipdate ? `(${customDateFormatter(currentItemStatus.nextwipdate, 5).displayForm})` : "";

        let maxQtyForSelection = 0;

        if (onhand && !nextWip) {
            maxQtyForSelection = onhand;
        }
        if (onhand && nextWip && dd >= gracePeriodForDelivery) {
            maxQtyForSelection = parseInt(onhand + parseInt(nextWip));
        }
        if (!onhand && !nextWip && dd < gracePeriodForDelivery) {
            maxQtyForSelection = 0;
        }

        if (nextWip && dd < gracePeriodForDelivery) {
            maxQtyForSelection = parseInt(nextWip);
        }


        // Adding the new Functionality for calculation & view item availability at same place
        const itemAvailability = [];
        if(item.accessory) {
            _.map(sizes_accessory, (size, index) => {
                _.forIn(item.sizes, (sizeKey, sizeIndex) => {
                    if (sizeKey === size.sizeName) {
                        itemAvailability.push(manageAvailableStatusForItem(item, currentItemStatus, size));
                    }
                });
            });
        } else {
            _.map(sizes, (size, index) => {
                _.forIn(item.sizes, (sizeKey, sizeIndex) => {
                    if (sizeKey === size.sizeName) {
                        itemAvailability.push(manageAvailableStatusForItem(item, currentItemStatus, size));
                    }
                });
            });
        }


        const newObject = {
            ...item,
            valueForSelect: item.group + " | " + item.fullStyle + " | " + item.description,
            availableStatus: currentItemStatus,
            split: false,
            itemAvailability: itemAvailability,
            deliveryDateInGracePeriod : dd >= gracePeriodForDelivery,
            // @Deprecated
            deliveryGreaterThanTwoMonths: dd >= gracePeriodForDelivery,
            maxQuantitySelection: maxQtyForSelection,
        }

        returner.push(newObject);
    });

    Logger.log("==== Final Parsed Array : ", returner);
    return _.orderBy(returner, ["fullStyle"], ["asc"]);

}

const manageAvailableStatusForItem = (item, itemSizeStatus, size) => {
    Logger.log("=================================================================");
    Logger.log("== Adjusting Item With Size");
    Logger.log("== Item :", item);
    Logger.log("== Size :", size);
    Logger.log("== Item Status :", itemSizeStatus);
    const currentSizeStatus = itemSizeStatus[size.sizeNumber];
    Logger.log("== Current Size Status :", currentSizeStatus);
    const atsStock = parseInt(currentSizeStatus.atsstock);
    const dd = dateDifferenceInWeeks(item.deliveryDate, new Date().toDateString());
    let nextWipQuantity = currentSizeStatus.hasOwnProperty("nextwipqty") ? parseInt(currentSizeStatus.nextwipqty) : 0;
    const onHand = parseInt(currentSizeStatus.onhand);
    let deliveryDate = customDateFormatter(getNewDate(item.deliveryDate, false, true), 1, true);
    let deliveryDateDisplay = customDateFormatter(deliveryDate.luxon, 7).displayForm;
    let nextWipDate = itemSizeStatus.hasOwnProperty("nextwipdate") && itemSizeStatus.nextwipdate ? itemSizeStatus.nextwipdate : "";
    let maxQtySelection = 0;
    const gracePeriodForDelivery = window.config.GRACE_TIME_DELIVERY_PERIOD;
    let alternativeQtyAndDeliveryDate = null;
    Logger.log("== Delivery Date", customDateFormatter(item.deliveryDate));
    Logger.log("== Ats Stock", atsStock);
    Logger.log("== Next Wip Quantity", nextWipQuantity);
    Logger.log("== On Hand", onHand);
    Logger.log("== Delivery In Weeks", dd);
    Logger.log("== Grace Period for Delivery", gracePeriodForDelivery);
    if (currentSizeStatus
        && currentSizeStatus.hasOwnProperty("wip")
        && Array.isArray(currentSizeStatus.wip)
        && currentSizeStatus.wip.length > 0) {
        Logger.log("=== Multiple WIP are Present | Wip Size : " , currentSizeStatus.wip.length);
        let earliestDateInSizeWip = "";
        _.map(currentSizeStatus.wip, (wip, index) => {
            if (index === 0) {
                earliestDateInSizeWip = getNewDate(wip.date);
            } else {
                if (earliestDateInSizeWip < getNewDate(wip.date)) {
                    earliestDateInSizeWip = getNewDate(wip.date);
                }
                nextWipQuantity = nextWipQuantity + wip.qty;
            }
        });
        nextWipDate = earliestDateInSizeWip;
    }


    Logger.log("== Checking for Carry Over Item");
    // Check for carryover item
    if (item.carryOver) {
        Logger.log("== Item is carry Over");

        // Case # 1
        // if atsstock > 0  (and no wip) we allow them to order up to atsstock value from the Immediate date
        if (atsStock > 0 && nextWipQuantity <= 0) {
            Logger.log("== CASE # 1 - Applied")
            Logger.log("== atsstock > 0  (and no wip) we allow them to order up to atsstock value from the Immediate date")
            maxQtySelection = atsStock
            deliveryDateDisplay = "Immediate"
            deliveryDate = customDateFormatter(new Date().toISOString(), 1, true);
        }

        // Case # 2
        // if atsstock > 0 and wip > 0 they can order atsstock+wip on wip date
        if (atsStock > 0 && nextWipQuantity > 0) {
            Logger.log("== CASE # 2 - Applied")
            Logger.log("== atsstock > 0 and wip > 0 they can order atsstock+wip on wip date")
            maxQtySelection = atsStock;
            const luxonWipDate = getNewDate(nextWipDate, false, true);
            deliveryDate = customDateFormatter(new Date().toISOString(), 1, true);
            deliveryDateDisplay = "Immediate"
            alternativeQtyAndDeliveryDate = {
                wipQty: nextWipQuantity,
                wipDate: customDateFormatter(luxonWipDate, 1, true),
                wipDateDisplay : customDateFormatter(luxonWipDate, 7).displayForm,
                originalDeliveryDate : deliveryDate
            }
        }

        // Case # 3
        // if atsstock <= 0 ( and no wip)  can't order
        if (atsStock <= 0 && nextWipQuantity <= 0) {
            Logger.log("== CASE # 3 - Applied")
            Logger.log("== atsstock <= 0 ( and no wip)  can't order")
            maxQtySelection = 0;
        }

        // Case # 4
        // if atsstock <= 0 and wip > 0 they can order atsstock+wip on wip date
        if (atsStock <= 0 && nextWipQuantity > 0) {
            Logger.log("== CASE # 4 - Applied")
            Logger.log("== atsstock <= 0 and wip > 0 they can order atsstock+wip on wip date")
            maxQtySelection = (atsStock) + (nextWipQuantity);
            const luxonWipDate = getNewDate(nextWipDate, false, true);
            Logger.log("== luxonWipDate : ", luxonWipDate);
            const diff = luxonWipDate.diff(getNewDate(deliveryDate.orignal));
            Logger.log("== diff : ", diff);
            const diffInDays = diff.as('days');
            Logger.log("== diffInDays : ", diffInDays);
            if(diffInDays > 0) {
                deliveryDate = customDateFormatter(luxonWipDate, 1, true);
            }
            deliveryDateDisplay = customDateFormatter(deliveryDate.luxon, 7).displayForm;
        }


    } else {

        Logger.log("== Item is not Carry Over");
        // Criteria # 1
        // If delivery date is greater than 2 months, ignoring the max quantity for selection
        if (dd >= gracePeriodForDelivery) {
            maxQtySelection = Infinity;
        } else {
            // Case # 1
            // if atsstock > 0  (and no wip) we allow them to order up to atsstock value from the delivery date
            if (atsStock > 0 && nextWipQuantity <= 0) {
                Logger.log("== CASE # 1 - Applied")
                Logger.log("== atsstock > 0  (and no wip) we allow them to order up to atsstock value from the Delivery date")
                maxQtySelection = atsStock
            }

            // Case # 2
            // if atsstock > 0 and wip > 0 they can order atsstock+wip on wip date
            if (atsStock > 0 && nextWipQuantity > 0) {
                Logger.log("== CASE # 2 - Applied")
                Logger.log("== atsstock > 0 and wip > 0 they can order atsstock+wip on wip date")
                maxQtySelection = (atsStock) + (nextWipQuantity);
                const luxonWipDate = getNewDate(nextWipDate, false, true);
                const diff = luxonWipDate.diff(getNewDate(deliveryDate));
                const diffInDays = diff.as('days');
                if(diffInDays > 0) {
                    deliveryDate = customDateFormatter(luxonWipDate, 1, true);
                }

                alternativeQtyAndDeliveryDate = {
                    wipQty: nextWipQuantity,
                    wipDate: customDateFormatter(luxonWipDate, 1, true),
                    wipDateDisplay : customDateFormatter(luxonWipDate, 7).displayForm,
                    originalDeliveryDate : deliveryDate
                }
            }

            // Case # 3
            // if atsstock <= 0 ( and no wip)  can't order
            if (atsStock <= 0 && nextWipQuantity <= 0) {
                Logger.log("== CASE # 3 - Applied")
                Logger.log("== atsstock <= 0 ( and no wip)  can't order")
                maxQtySelection = 0;
            }

            // Case # 4
            // if atsstock <= 0 and wip > 0 they can order atsstock+wip on wip date
            if (atsStock <= 0 && nextWipQuantity > 0) {
                Logger.log("== CASE # 4 - Applied")
                Logger.log("== atsstock <= 0 and wip > 0 they can order atsstock+wip on wip date")
                maxQtySelection = (atsStock) + (nextWipQuantity);
                const luxonWipDate = getNewDate(nextWipDate, false, true);
                Logger.log("== luxonWipDate : ", luxonWipDate);
                const diff = luxonWipDate.diff(getNewDate(deliveryDate.orignal));
                Logger.log("== diff : ", diff);
                const diffInDays = diff.as('days');
                Logger.log("== diffInDays : ", diffInDays);
                if(diffInDays > 0) {
                    deliveryDate = customDateFormatter(luxonWipDate, 1, true);
                }
            }

        }
        deliveryDateDisplay = customDateFormatter(deliveryDate.luxon, 7).displayForm;
    }

    // Checking for Negative Value
    if(maxQtySelection < 0) {
        maxQtySelection = 0;
    }

    Logger.log("== Max Quantity Selection : ", maxQtySelection);
    Logger.log("== Delivery Date : ", deliveryDate);
    Logger.log("== Delivery Date Display : ", deliveryDateDisplay);

    return {
        sizeNumber: size.sizeNumber,
        size: size,
        maxQtySelection: maxQtySelection,
        deliveryDate: deliveryDate,
        currentSizeStatus: currentSizeStatus,
        deliveryDateDisplay: deliveryDateDisplay,
        alternativeQtyAndDeliveryDate: alternativeQtyAndDeliveryDate,
        view: {
            sizeCode: size.sizeNumber,
            available: Number.isFinite(maxQtySelection) ? maxQtySelection : "∞",
            cellColor: (maxQtySelection === 0) ? window.config.NOT_AVAILABLE_ITEM_ROW_COLOR : "transparent"
        }
    };

}

export const extractSizesFromAllItemsList = (allItems, accessory = false) => {
    const sizes = [];
    // let sizeNumber = 1;


    _.map(allItems, (singleItem, index) => {

        if (!accessory) {
            if (!singleItem.accessory) {
                _.forIn(singleItem.sizes, (size, sizeIndex) => {
                    // Logger.log("Single Size " + index + ": ", size);
                    if (sizeIndex !== "sizeNo" && sizeIndex !== "id" && size !== null) {

                        // Logger.log(sizeIndex, size);
                        const checkForArray = _.filter(sizes, (p) => p.sizeName === size);
                        // Logger.log("CHECK IN ARRAY", checkForArray);
                        if (!checkForArray.length) {
                            let sizeNo = sizeIndex.replace("size", "");
                            const overlayStyle = window.config.OVERLAY_STYLES ? window.config.OVERLAY_STYLES : [];
                            const checkOverlayStyle = _.find(overlayStyle, p => p.originalStyle === size);
                            const checkOverlayStyleAlternative = _.find(overlayStyle, p => p.alternativeStyle === size);
                            sizes.push({
                                sizeName: size,
                                sizeNumber: "SZ" + sizeNo,
                                displaySizeName: checkOverlayStyle ? checkOverlayStyle.alternativeStyle : checkOverlayStyleAlternative ? checkOverlayStyleAlternative.originalStyle : size
                            });
                        } else {
                            // Logger.log("Size Already In Array", size);
                        }
                    }

                })
            }
        } else {
            if (singleItem.accessory) {
                _.forIn(singleItem.sizes, (size, sizeIndex) => {
                    // Logger.log("Single Size " + index + ": ", size);
                    if (sizeIndex !== "sizeNo" && sizeIndex !== "id" && size !== null) {

                        // Logger.log(sizeIndex, size);
                        const checkForArray = _.filter(sizes, (p) => p.sizeName === size);
                        // Logger.log("CHECK IN ARRAY", checkForArray);
                        if (!checkForArray.length) {
                            let sizeNo = sizeIndex.replace("size", "");
                            const overlayStyle = window.config.OVERLAY_STYLES ? window.config.OVERLAY_STYLES : [];
                            const checkOverlayStyle = _.find(overlayStyle, p => p.originalStyle === size);
                            sizes.push({
                                sizeName: size,
                                sizeNumber: "SZ" + sizeNo,
                                displaySizeName: checkOverlayStyle ? checkOverlayStyle.displaySizeName : size
                            });
                        } else {
                            // Logger.log("Size Already In Array", size);
                        }
                    }

                })
            }
        }

    });

    return sizes;


}

export const extractSizeForSplitItem = (item) => {
    const sizes = [];
    _.forIn(item.sizes, (size, sizeIndex) => {
        // Logger.log("Single Size ", size);

        if (sizeIndex !== "sizeNo" && sizeIndex !== "id" && size !== null) {

            // Logger.log(sizeIndex, size);
            const checkForArray = _.filter(sizes, (p) => p.sizeName === size);
            // Logger.log("CHECK IN ARRAY", checkForArray);
            if (!checkForArray.length) {
                let sizeNo = sizeIndex.replace("size", "");
                sizes.push({
                    sizeName: size,
                    sizeNumber: "SZ" + sizeNo
                });
            } else {
                // Logger.log("Size Already In Array", size);
            }
        }

    });

    return sizes;
}

// @Deprecated
export const setSizesForProduct = (sizes, product, cb) => {
    // Logger.log("Product", product)
    // Logger.log("Size", sizes);

    const returner = [];
    const sizeList = [];

    _.forIn(sizes, (size, index) => {
        // Logger.log("Single Size " + index + ": ", size);

        if (index !== "sizeNo" && index !== "id" && size !== null) {

            // Logger.log(index, size);
            const checkForArray = _.filter(sizeList, (p) => p === size);
            // Logger.log("CHECK IN ARRAY", checkForArray);
            if (!checkForArray.length) {
                sizeList.push(size);
                returner.push(size);
            } else {
                // Logger.log("Size Already In Array", size);
            }
        }

    })
    // Logger.log("SIZE LIST", returner);

    return returner;


}

export const mapSizesToItems = (sizes, items, cb) => {
    Logger.log("Items", items)
    Logger.log("Size", sizes);
    const allItems = [];
    const allSizes = [];

    _.map(items, (singleItem, key) => {

        const newItem = {...singleItem};
        const getSize = _.find(sizes, p => p.sizeNo === singleItem.sizeScale);

        Logger.log("Single Size: ", getSize);

        const sizeIndex = [];

        for (let i = 0; i < SIZES_LENGTH; i++) {
            Logger.log("Checking for: ", getSize[SIZES_INDEXES[i]]);
            if (getSize[SIZES_INDEXES[i]]) {
                sizeIndex.push({
                    sizeName: getSize[SIZES_INDEXES[i]],
                    size: SIZES_INDEXES[i],
                    available: true
                });
            }

        }
        newItem.allSizesParsed = sizeIndex;
        allItems.push(newItem);

    });
    const getParticularSizes = _.find(sizes, p => p.sizeNo === items[0].sizeScale);
    for (let i = 0; i < SIZES_LENGTH; i++) {
        if (getParticularSizes[SIZES_INDEXES[i]]) {
            allSizes.push(getParticularSizes[SIZES_INDEXES[i]]);
        }
    }

    Logger.log("All Sizes for: ", allSizes);

    return {sizes: allSizes, items: allItems};

}


export const getParsedOrder = (sendingOrders) => {
    const parsedOrderReturning = [];
    Logger.log("Order : ", sendingOrders);


    _.map(sendingOrders, (order, index) => {
        const parsedOrder = {};
        parsedOrder.poNumber = order.poNumber;
        // parsedOrder.deliveryDate = moment(new Date(order.deliveryDate)).format("YYYY-MM-DD");
        parsedOrder.deliveryDate = order.deliveryDate;
        parsedOrder.season = order.bookSeason;
        parsedOrder.orderType = order.orderType;
        parsedOrder.remarks = order.remarks;
        parsedOrder.customerId = order.customerId;
        parsedOrder.storeId = order.storeId;
        parsedOrder.shippingType = order.shippingType;
        parsedOrder.cancelDate = order.cancelDate;
        parsedOrder.orderTotal = order.orderTotal;
        parsedOrder.totalQuantity = order.totalQuantity;
        parsedOrder.orderDetails = [];
        parsedOrder.discount = order.discount;
        parsedOrder.teamDiscount = order.teamDiscount;
        // parsedOrder.discount = order.getDiscount ? window.config.DEFAULT_DISCOUNT_VALUE : 0;
        // parsedOrder.teamDiscount = order.getTeamDiscount ? window.config.DEFAULT_TEAM_DISCOUNT_VALUE : 0;

        _.map(order.products, (singleProduct, index) => {
            parsedOrder.orderDetails.push({
                productId: singleProduct.id,
                qty: singleProduct.qty,
                cost: singleProduct.cost,
                style: singleProduct.fullStyle,
                embroideryDetails: singleProduct.embroideryDetails
            })
        });

        parsedOrderReturning.push(parsedOrder);
    });

    return parsedOrderReturning;
}

export const filterCustomers = (allCustomers) => {
    const returner = [];

    _.map(allCustomers, (customer, index) => {
        returner.push({
            ...customer,
            selectTitle: customer.name + " | " + customer.customerNumber + " | " + customer.state
        })
    })


    return _.sortBy(returner, p => p.name);


}
export const filetrStore = (allStores) => {
    const returner = [];

    _.map(allStores, (store, index) => {
        returner.push({
            ...store,
            selectTitle: store.name + " | " + store.city + " | " + store.storeNumber
        })
    })


    return returner;

}
