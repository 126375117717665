// Library
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {Col, Row} from 'react-bootstrap';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ErrorMessage, FieldArray, Form, Formik} from 'formik';
import * as yup from 'yup';
import {FormHelperText} from "@mui/material";

import {FormGroup, Label} from 'reactstrap';
// Components
import Button from '../../Components/Button';
import Loader from '../../Components/Loader';
import SelectV2 from "../../Components/SelectV2";
import InputV2 from "../../Components/InputV2";
import ToggleButton from "../../Components/ToggleButton";
import DatePickerV2 from "../../Components/DatePickerV2";
// Container
import PageContainer from "../../Containers/PageContainer";
// Logger
import Logger from "../../Utils/Logger";
import Colors from "../../Config/Colors";
// Redux Actions
import {_resetState} from "../../Store/Cart/action";
import {
    addOrder,
    _resetState as _orderResetState,
    getAllVisibleOrderAndShippingTypes,
    sendNotificationEmail, getLatestSeasonsAndDivisions, sendEmbroideryAlertEmail
} from '../../Store/Order/action';
// Helpers
import {
    orderTypeFinder,
    toaster,
    getNewDate,
    getAmericanDateFormatter,
    getTrackerDO, excludeSplitFromId, arraysAreSimilar
} from '../../Utils/Helpers';
import {logTrack} from "../../Store/Tracker/actions";
import {PAGES} from "../../Utils/CommonConstants";
import logger from "../../Utils/Logger";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import FontIcon from "../../Components/FontIcon";
import UniqueCartItemViewer from '../../Containers/CartUniqueItemViewer';
import {getUniqueOrders} from "../../Store/Cart/parser";


yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {

    return this.test('unique', message, function (list) {
        // Logger.log("MESAGE", message);

        return list.length === new Set(list.map(mapper)).size;
    });
});

// Constants
const PUD_DISCOUNT = "getDiscount";
const TEAM_DISCOUNT = "getTeamDiscount";

// Validations
const validationSchema = yup.object().shape({
    distinctOrders: yup.array()
        .of(
            yup.object().shape({
                poNumber: yup.string().required('PO is required').min(window.config.PO_NUMBER_LENGTH.min, "Too Short!!").max(window.config.PO_NUMBER_LENGTH.max, "Too Long!!"), // these constraints take precedence
                orderType: yup.string().required('Order Type is required'), // these constraints take precedence
                bookSeason: yup.string().required('Book Season is required'), // these constraints take precedence
                shippingType: yup.string().required('Shipping Type is required'), // these constraints take precedence
                cancelDate: yup.string().required("Cancel Date is Required")
            })
        )
        .unique('PO Number Should be Unique', a => a.poNumber)
        .required("Please add atleast on detail")

});
// Initial Values
const initialDefaultValues = {
    distinctOrders: [],

}

const CheckoutPage = (props) => {

    // Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(initialDefaultValues);
    const [orderTypeDisabled, setOrderTypeDisabled] = useState([]);
    const [pudDiscountDisabled, setPudDiscountDisabled] = useState([]);
    const [teamDiscountDisabled, setTeamDiscountDisabled] = useState([]);
    const [isEmbroideryOrder, setIsEmbroideryOrder] = useState(false);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TRACKER
    const tracker = useSelector(state => state.tracker);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Store State
    const cartState = useSelector(state => state.cart);
    const orderState = useSelector(state => state.order);
    const {themeMode} = useSelector(state => state.theme);
    // const color = themeMode === 'light' ? Colors.dark : Colors.light;

    // Drop Down Changer
    const dropDownChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index: ", index);
        formik.setFieldValue(fieldName, index ? value[index] : value.id);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "DROP DOWN CHANGED", PAGES.CHECKOUT, {
            value: value,
            fieldName: fieldName
        })));


        // Logger.log("Formik", formik);
    }

    // Input Changer
    const inputChanger = (value, formik, fieldName, index) => {
        // Logger.log("VALUE: ", value);
        // Logger.log("FORMIK: ", formik);
        // Logger.log("Field Name: ", fieldName);
        // Logger.log("index: ", index);
        formik.setFieldValue(fieldName, value);

        dispatch(logTrack(getTrackerDO(tracker.tracker, "INPUT CHANGED", PAGES.CHECKOUT, {
            value: value,
            fieldName: fieldName
        })));

        // Logger.log("Formik", formik);
    }

    const discountChanger = (value, formik, fieldName, orderTypeField, index, valueCatcher, extraValueCatcher) => {
        // debugger;
        formik.setFieldValue(fieldName, value);

        Logger.log("FIELD NAME: ", fieldName);
        if (valueCatcher === PUD_DISCOUNT) {
            if (window.config.STAFF_ENABLE_ON_DISCOUNT) {
                const orderTypes = [...orderTypeDisabled];
                const teamDiscounts = [...teamDiscountDisabled];
                if (value) {
                    formik.setFieldValue(extraValueCatcher, false);
                    formik.setFieldValue(orderTypeField, window.config.STAFF_DISABLE_ID);
                    orderTypes.push(index);
                    teamDiscounts.push(index);
                } else {
                    formik.setFieldValue(orderTypeField, "");
                    formik.setFieldValue(extraValueCatcher, false);
                    _.remove(orderTypes, p => _.isEqual(parseInt(p), parseInt(index)));
                    _.remove(teamDiscounts, p => _.isEqual(parseInt(p), parseInt(index)));

                }
                setOrderTypeDisabled(orderTypes);
                setTeamDiscountDisabled(teamDiscounts);
            }
        } else {
            const pudDisabled = [...orderTypeDisabled];
            if (value) {
                formik.setFieldValue(pudDisabled, false);
                pudDisabled.push(index);
            } else {
                formik.setFieldValue(orderTypeField, false);
                _.remove(pudDisabled, p => _.isEqual(parseInt(p), parseInt(index)))
            }
            setPudDiscountDisabled(pudDisabled);
        }
    }

    // Logger.log("ORDER TYPES : ", orderTypeDisabled);

    // Submit Function
    const onSubmit = (values, {setStatus, setSubmitting}) => {

        dispatch(logTrack(getTrackerDO(tracker.tracker, "CHECKOUT SUBMITTED", PAGES.CHECKOUT, values)));

        if (!cartState.selectedCustomer) {
            toaster("There is not customer selected for this cart", "danger");
            return;
        }
        if (!cartState.selectedStore) {

            toaster("There is not store selected for this cart", "danger");
            return;
        }

        Logger.log("VALUES: ", values);
        setLoading(true);
        setSubmitting(true);
        setStatus(false);

        const orders = [...values.distinctOrders];
        Logger.log("orders: ", orders);

        const errors = [];

        const sendingOrders = [];
        _.map(orders, (singleOrder, index) => {
            const totalCost = [];
            const totalQuantity = [];
            singleOrder.customerId = cartState.selectedCustomer.id;
            singleOrder.storeId = cartState.selectedStore.id;
           const products =  singleOrder.products = singleOrder.items.items;

            _.map(products, (singleProduct) => {
                totalCost.push(singleProduct.cost * singleProduct.qty.totalQuantity)
                totalQuantity.push(singleProduct.qty.totalQuantity)
                singleProduct.id = excludeSplitFromId(singleProduct.id);
                if(singleOrder.items.items.embroideryAvaialble &&
                    singleProduct.items.items.has("shouldAddEmbroidery") &&
                    singleProduct.items.items.shouldAddEmbroidery &&
                    singleOrder.items.items.embroideryDetails
                ) {
                    singleProduct.embroideryDetails = singleOrder.items.embroideryDetails;
                }
                if (cartState.selectedCustomer
                    && cartState.selectedCustomer.hasOwnProperty("discountCode")
                    && cartState.selectedCustomer.discountCode != null) {

                }
            });


            if (window.config.DEFAULT_DISCOUNT_BY_CUSTOMER
                && cartState.selectedCustomer
                && cartState.selectedCustomer.hasOwnProperty("discountCode")
                && cartState.selectedCustomer.discountCode != null) {
                singleOrder.discount = parseInt(cartState.selectedCustomer.discountCode);
                singleOrder.teamDiscount = 0;
            } else {
                singleOrder.discount = singleOrder.getDiscount ? window.config.DEFAULT_DISCOUNT_VALUE : 0;
                singleOrder.teamDiscount = singleOrder.getTeamDiscount ? window.config.DEFAULT_TEAM_DISCOUNT_VALUE : 0;
            }
            singleOrder.orderTotal = _.sum(totalCost);
            singleOrder.totalQuantity = _.sum(totalQuantity);
            sendingOrders.push(singleOrder);
        });

        Logger.log("Cart Values", cartState.cartDetailRevamped);
        Logger.log("Sending Orders Afet assigning Products", sendingOrders);
        Logger.log("Is Embroidery Order", isEmbroideryOrder);

        dispatch(addOrder(sendingOrders, (content, err) => {
            dispatch(logTrack(getTrackerDO(tracker.tracker, "CHECKOUT RESPONSE", PAGES.CHECKOUT, {
                content: content,
                error: err
            })));
            let sev = "";
            let message = "";
            if (err) {
                Logger.log("Errors: ", errors);

                message = "There are errors in some orders";
                sev = "danger";
                setLoading(false);
                setSubmitting(false);
            } else {
                message = "Successfully Submitted order";
                sev = "success";
                const uuid = content.uuid;
                Logger.log("UUID: ", uuid);
                dispatch(sendNotificationEmail(uuid));
                logger.log("Products Check for EMB ", cartState.cartDetailRevamped);
                const checkForEmbroidery = _.filter(cartState.cartDetailRevamped, p => p.shouldAddEmbroidery === true);
                logger.log("checkForEmbroidery -->: ", checkForEmbroidery);
                if (checkForEmbroidery.length) {
                    dispatch(sendEmbroideryAlertEmail(uuid));
                }
                setTimeout(() => {
                    dispatch(_resetState());
                    dispatch(_orderResetState());
                    setLoading(false);
                    setSubmitting(false);
                    history.push("/order-acceptance/" + uuid);

                }, 6000);

            }


            toaster(message, sev);
        }));
    }

    // Use Effect
    useEffect(() => {
        // formProducer();
        if (!cartState.selectedCustomer) {
            toaster("There is not customer selected for this cart", "danger");
            history.goBack();
        }
        if (!cartState.selectedStore) {

            toaster("There is not store selected for this cart", "danger");
            history.goBack();
        }

        dispatch(getAllVisibleOrderAndShippingTypes());
        dispatch(getLatestSeasonsAndDivisions());
    }, []);


    useEffect(() => {

        if (!cartState.cartDetailRevamped.length) {
            history.push("/home");
        } else {
            // const distinctOrders = cartState.uniqueCartItems;
            // Logger.log("Distinct Orders", distinctOrders);
            // if (distinctOrders.length !== uniqueOrders.length) {
            //     setUniqueOrders(distinctOrders);
            // }
        }

    }, [cartState]);

    useEffect(() => {
        const uniqueCartItems = getUniqueOrders(cartState.cartDetailRevamped);
        Logger.log("USER EFFECT UNIQUE ITEMS : " , uniqueCartItems);
        if(uniqueCartItems && uniqueCartItems.length > 0) {
            formProducer(uniqueCartItems);
        }

    }, []);

    // useEffect(() => {
    //
    // }, [cartState.uniqueCartItems]);




    const formProducer = (uniqueItems) => {
        const shippingType = _.find(orderState.allShippingTypes , p => p.carrierAbbr === cartState.selectedCustomer.shipViaNumber);
        Logger.log("SHIPPING TYPE: ", shippingType);
        const formForDistinctOrders = [];
        if (uniqueItems) {

            uniqueItems.forEach((item, key) => {

                formForDistinctOrders.push(
                    {
                        poNumber: "",
                        remarks: "",
                        bookSeason: "",
                        orderType: "",
                        deliveryDate: item.items[0].changeableDeliveryDate.formatted,
                        shippingType: shippingType ? shippingType.id : "",
                        getDiscount: false,
                        getTeamDiscount: false,
                        cancelDate: getNewDate(item.items[0].changeableDeliveryDate.luxon).plus({months: 1}),
                        minCancelDate: getNewDate(item.items[0].changeableDeliveryDate.luxon).plus({months: 1}),
                        items: item
                    }
                )

            });
        }
        setInitialValues({distinctOrders: formForDistinctOrders});
    }


    // Logger.log("STATE : ", loading);
    Logger.log("=== Unique Orders", cartState.uniqueCartItems);
    const formSpan = 5;
    const formOffset = 4;
    return (
        <>
            <PageContainer>
                <h3 className={themeMode === 'light' ? 'text-center text-dark' : 'text-center text-light'}>Checkout</h3>
                {/*<h5 className={"text-center"}> Shipping*/}
                {/*    via: <b>{cartState.selectedCustomer ? cartState.selectedCustomer.shipViaNumber : ""}</b></h5>*/}
                <hr/>
                <Row>
                    <Col>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(formikProps) => (
                                <Form>
                                    <FieldArray name={"distinctOrders"}>

                                        <>
                                            {formikProps.values.distinctOrders.map((order, index) => (
                                                <>
                                                    <h4 className={themeMode === 'light' ? 'text-center mt-5 text-dark' : 'text-light text-center mt-5'}>Delivery # {index + 1}</h4>
                                                    <h5  className={themeMode === 'light' ? ' text-dark text-center' : 'text-light text-center'}>{cartState.uniqueCartItems.length && typeof cartState.uniqueCartItems[index] !== 'undefined' ? getAmericanDateFormatter(cartState.uniqueCartItems[index].deliveryDate) : null}
                                                        {" "} - {cartState.uniqueCartItems.length && typeof cartState.uniqueCartItems[index] !== 'undefined' ? cartState.uniqueCartItems[index].division : null}
                                                        <FontIcon
                                                            onClick={() => {
                                                                if(cartState.uniqueCartItems.length && typeof cartState.uniqueCartItems[index] !== 'undefined') {
                                                                    Logger.log(cartState.uniqueCartItems[index].items);
                                                                    Logger.log(cartState.uniqueCartItems[index].division);
                                                                    setIsOpen(cartState.uniqueCartItems[index]);
                                                                }

                                                            }}
                                                            style={{cursor: "pointer", margin: "0px 10px"}}
                                                            icon={faEye}
                                                            title={"View Items"}
                                                            className={"text-success"}
                                                        />
                                                    </h5>
                                                    <Row className={"mt-4"}>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <InputV2
                                                                id="poNUmber"
                                                                name={`distinctOrders.${index}.poNumber`}
                                                                label="PO Number"
                                                                placeholder={"Enter PO Number"}
                                                                changer={(e) => inputChanger(e.target.value, formikProps, `distinctOrders.${index}.poNumber`)}
                                                            />
                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`distinctOrders.${index}.poNumber`}/>
                                                            </div>
                                                            {formikProps.errors.hasOwnProperty("distinctOrders") && !Array.isArray(formikProps.errors.distinctOrders) ? (
                                                                <div>
                                                                    <ErrorMessage component={"div"}
                                                                                  className={"text-danger text-center"}
                                                                                  name={`distinctOrders`}/>
                                                                </div>
                                                            ) : null}

                                                        </Col>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>

                                                            <SelectV2
                                                                valueIndex={"label"}
                                                                keyIndex={"label"}
                                                                data={orderState.season}
                                                                value={formikProps.values.distinctOrders[index].bookSeason}
                                                                placeholder={"Select Season"}
                                                                label={"Season"}
                                                                changer={(value) => dropDownChanger(value, formikProps, `distinctOrders.${index}.bookSeason`, "label")}/>

                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`distinctOrders.${index}.bookSeason`}/>
                                                            </div>
                                                        </Col>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <SelectV2
                                                                valueIndex={"userRef"}
                                                                data={orderState.allOrderTypes.length ? _.sortBy(orderState.allOrderTypes, p => p.userRef) : []}
                                                                value={formikProps.values.distinctOrders[index].orderType}
                                                                label={"Order Type"}
                                                                placeholder={"Select Order Type"}
                                                                disabled={orderTypeFinder(orderTypeDisabled, index)}
                                                                changer={(value) => dropDownChanger(value, formikProps, `distinctOrders.${index}.orderType`)}/>
                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`distinctOrders.${index}.orderType`}/>
                                                            </div>
                                                        </Col>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <SelectV2
                                                                valueIndex={"carrierAbbr"}
                                                                data={orderState.allShippingTypes.length ? orderState.allShippingTypes : []}
                                                                value={formikProps.values.distinctOrders[index].shippingType}
                                                                placeholder={"Select Shipping Types"}
                                                                label={"Shipping Types"}
                                                                changer={(value) => dropDownChanger(value, formikProps, `distinctOrders.${index}.shippingType`)}/>
                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`distinctOrders.${index}.shippingType`}/>
                                                            </div>
                                                        </Col>
                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <FormGroup row style={{margin: "15px 0px"}}>
                                                                <Label for={"cancelDate"}
                                                                       className={themeMode === 'light' ? 'text-dark' : 'text-light'}
                                                                       sm={3}>Cancel Date</Label>
                                                                <Col sm={9}>
                                                                    <DatePickerV2
                                                                        name={"cancelDate"}
                                                                        changer={(date) => inputChanger(date, formikProps, `distinctOrders.${index}.cancelDate`)}
                                                                        minDate={formikProps.values.distinctOrders[index].minCancelDate}
                                                                        value={formikProps.values.distinctOrders[index].cancelDate}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            {/*<DatePickerForForm*/}
                                                            {/*    label={"Cancel Date"}*/}
                                                            {/*    className={"form-control"}*/}
                                                            {/*    name={"cancelDate"}*/}
                                                            {/*    minDate={formikProps.values.distinctOrders[index].minCancelDate}*/}
                                                            {/*    selected={formikProps.values.distinctOrders[index].cancelDate}*/}
                                                            {/*    changer={(date) => inputChanger(date, formikProps, `distinctOrders.${index}.cancelDate`)}*/}
                                                            {/*    // dateFormat={"yyyy-MM-dd"}*/}
                                                            {/*/>*/}

                                                            <div>
                                                                <ErrorMessage component={"div"}
                                                                              className={"text-danger text-center"}
                                                                              name={`distinctOrders.${index}.cancelDate`}/>
                                                            </div>
                                                        </Col>

                                                        <Col sm={{span: formSpan, offset: formOffset}}>
                                                            <InputV2
                                                                type={"textarea"}
                                                                margin={'dense'}
                                                                multiline
                                                                maxRows={4}
                                                                rows={4}
                                                                fullWidth
                                                                id="remarks"
                                                                name={`distinctOrders.${index}.remarks`}
                                                                label="Remarks"
                                                                variant="standard"
                                                                changer={(e) => inputChanger(e.target.value, formikProps, `distinctOrders.${index}.remarks`)}
                                                            />
                                                        </Col>
                                                        {window.config.DEFAULT_DISCOUNT_BY_CUSTOMER
                                                        && cartState.selectedCustomer
                                                        && cartState.selectedCustomer.hasOwnProperty("discountCode")
                                                        && cartState.selectedCustomer.discountCode != null ? (
                                                            <>
                                                                <span
                                                                    className={"text-center"}>Discount is applied : {parseInt(cartState.selectedCustomer.discountCode)}%</span>
                                                            </>
                                                        ) : (
                                                            <Col sm={{span: formSpan, offset: formOffset}}>
                                                                <Row style={{marginTop: "20px"}}>
                                                                    <Col sm={{span: 3, offset: 3}}>
                                                                        <ToggleButton
                                                                            disabled={orderTypeFinder(pudDiscountDisabled, index)}
                                                                            checked={formikProps.values.distinctOrders[index].getDiscount}
                                                                            changer={(value) => discountChanger(value, formikProps, `distinctOrders.${index}.getDiscount`, `distinctOrders.${index}.orderType`, index, PUD_DISCOUNT, `distinctOrders.${index}.getTeamDiscount`)}/>
                                                                        <FormHelperText className={themeMode === 'light' ? 'text-dark' : 'text-light'}>PUD Discount?</FormHelperText>
                                                                    </Col>
                                                                    <Col sm={{span: 3, offset: 2}}>
                                                                        <ToggleButton
                                                                            disabled={orderTypeFinder(teamDiscountDisabled, index)}
                                                                            checked={formikProps.values.distinctOrders[index].getTeamDiscount}
                                                                            changer={(value) => discountChanger(value, formikProps, `distinctOrders.${index}.getTeamDiscount`, `distinctOrders.${index}.getDiscount`, index, TEAM_DISCOUNT)}/>
                                                                        <FormHelperText className={themeMode === 'light' ? 'text-dark' : 'text-light'}>Team Discount?</FormHelperText>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        )}

                                                    </Row>
                                                </>
                                            ))}

                                        </>

                                    </FieldArray>
                                    <Row>
                                        <Col sm={{span: 2, offset: 5}}>
                                            {loading ? (
                                                <Loader/>
                                            ) : (
                                                <Button
                                                    clicker={() => Logger.log("FORMIK", formikProps)}
                                                    fullWidth
                                                    type="submit"
                                                    style={{color: Colors.white, marginTop: "30px"}}
                                                    disabled={loading}
                                                >
                                                    Create Order
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>

                            )}
                        </Formik>
                    </Col>
                </Row>



                <UniqueCartItemViewer
                    data={isOpen}
                    toggler={() => isOpen ? setIsOpen(null) : null}
                />
            </PageContainer>
        </>
    )
}
export default connect(null, {
    _resetState,
    addOrder,
    _orderResetState,
    getAllVisibleOrderAndShippingTypes
})(CheckoutPage);
