import Logger from "./Logger";
import {toast} from "react-toastify";
import {MONTHS} from "./CommonConstants";
import _ from 'lodash';
import Swal from "sweetalert2";
import {DateTime} from "luxon";
import crypto from 'crypto';
import logger from "./Logger";

export const toAbsoluteUrl = (pathname) => {
    return getPublicUrl() + pathname;
}
export const getPublicUrl = () => {
    return process.env.PUBLIC_URL;
}
export const singleCartIdGenerator = (size, productId, userId, isSplit = false) => {
    let cartUniqueId = "s_" + size + "_p_" + productId + "_u_" + userId;

    if (isSplit) {
        cartUniqueId = cartUniqueId + "splt";
    }

    return cartUniqueId;
}
export const termsDropDown = () => {
    return [
        {
            id: "NET15",
            name: "NET15"
        },
        {
            id: "NET30",
            name: "NET30"
        }
    ]
};

export const orderTypeDropDown = () => {
    return [
        {
            id: "INITIAL",
            name: "INITIAL",
        },
        {
            id: "TEAM",
            name: "TEAM"
        }
    ]
};
export const isValidDate = (date) => {
    const d = new Date(date);
    let validDate = false;
    if (Object.prototype.toString.call(d) === "[object Date]") {
        validDate = !isNaN(d.getTime());
    } else {
        validDate = false;
    }
    Logger.log("Valid Date: ", validDate);

    return validDate;
}
export const convertDateTimezone = (date, timeZone = window.config.DEFAULT_TIME_ZONE) => {

    // const dated = new Date(date);
    // Logger.log("Date Before Converting: ", dated);
    //
    // // suppose the date is 12:00 UTC
    // var invdate = new Date(dated.toLocaleString('en-US', {
    //     timeZone: ianatz
    // }));
    //
    // // then invdate will be 07:00 in Toronto
    // // and the diff is 5 hours
    // var diff = dated.getTime() - invdate.getTime();
    //
    // // so 12:00 in Toronto is 17:00 UTC
    //
    // Logger.log("Date After Converting: ", new Date(dated.getTime() - diff))
    // return new Date(dated.getTime() - diff);


    // const datee = moment(new Date(date)).tz(ianatz).format("YYYY-MM-DD");
    Logger.log("Date After Converting: ", date);
    // return datee

    // const datee = new Date(date);
    // Logger.log("Date Before Converting: " , datee.toISOString());
    // const utcDate = zonedTimeToUtc(datee.toISOString() , timeZone);
    //
    //

    // const clonedDate = toDate(utcDate, timeZone);
    // Logger.log("Date Checker: " , format(clonedDate,  'yyyy-MM-dd', { timeZone }));
    //
    //
    // return utcDate;

    const parsedDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: "America/Toronto"}))
    Logger.log("Date After Converting: ", parsedDate);

    return parsedDate;


}


export const getDataIndex = (pageNumber, dataIndex) => {
    return ((pageNumber - 1) * window.config.PAGINATION_LIMIT) + 1 + dataIndex;
}


export const toaster = (message, severity = "danger", progress = undefined, autoClose = 5000, position = "top-center") => {
    const options = {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: progress,
        theme: "colored"
    }
    switch (severity) {
        case "danger":
            toast.error(message, options);
            break;
        case "warn":
            toast.warn(message, options);
            break;
        case "success":
            toast.success(message, options);
            break;
        case "info" :
            toast.info(message, options);
            break;
        default:
            toast.info(message, options);
            break;
    }

}
export const priceViewer = (price) => {
    return `$${price.toFixed(2)}`;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
export const customDateFormatter = (date, option = 1, minCurrentDate = false) => {
    // Logger.log("DATE: ", date);

    let day = "";
    let month = "";
    let year = "";
    let halfYear = "";
    let fullDay = "";
    let luxonDate = "";
    const currentDate = new Date();
    const orignal = date;

    let dateSplit = null;
    // debugger;

    try {

        if (date instanceof Date) {

            if (date < currentDate && minCurrentDate) {
                date = getNewDate(false, "LL/dd/yyyy");
                luxonDate = getNewDate();
            } else {
                luxonDate = date = getNewDate(new Date(date));

                //luxonDate = getNewDate(dateSplit[2] + "-" + String(dateSplit[0]).padStart(2, '0') + "-" + String(dateSplit[1]).padStart(2, '0'));
            }


        } else {
            const checkDate = getNewDate(date, "LL/dd/yyyy");
            // Logger.log("Check Date ", checkDate);

            if (checkDate < currentDate && minCurrentDate) {
                // date = formatter.format(new Date());
                date = getNewDate(false, "LL/dd/yyyy");
                luxonDate = getNewDate();
            } else {
                // date = formatter.format(checkDate.toJSDate());
                luxonDate = getNewDate(date);
                date = checkDate;
            }
        }


        // Logger.log("DATE GETTER: ", date);

        dateSplit = date.toLocaleString().split("/");

        Logger.log("LUXON DATE: ", luxonDate);
        Logger.log("DATE SPLITTER: ", dateSplit);

        if (dateSplit.length < 3) {
            logger.log("RETURNING STRING DATE ,", date);
            return date;
        }

        year = dateSplit[2];
        month = parseInt(dateSplit[0]);
        day = dateSplit[1];
        halfYear = year.slice(-2);
        fullDay = String(day).padStart(2, '0');


        // Logger.log("DATE year: " , year);
        // Logger.log("DATE month: " , month);
        // Logger.log("DATE day: " , day);
        // Logger.log("DATE MONTHS: " , MONTHS);

        const monthGetter = _.find(MONTHS, p => p.number === month);

        switch (option) {
            case 1: // YYYY-mm-DD
                return {
                    formatted: year + "-" + month + "-" + day,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate
                }
            case 2: // DD-mm-YYYY
                return {
                    formatted: day + "-" + month + "-" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate
                }
            case 3: // DD MM, YYYY
                return {
                    formatted: day + " " + monthGetter.full + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate
                }
            case 4: // DD M, YYYY
                return {
                    formatted: day + " " + monthGetter.half + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate
                }
            case 5: // MM/DD
                return {
                    formatted: day + " " + monthGetter.half + ", " + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: monthGetter.string + "/" + day,
                    luxon: luxonDate
                }
            case 6: // dd/mm/yy
                return {
                    formatted: day + "/" + monthGetter.half + "/" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + "/" + monthGetter.string + "/" + halfYear,
                    luxon: luxonDate
                }
            case 7: // mm/dd/yy
                return {
                    formatted: monthGetter.half + "/" + fullDay + "/" + year,
                    month: monthGetter,
                    original: orignal,
                    displayForm: monthGetter.string + "/" + fullDay + "/" + halfYear,
                    luxon: luxonDate
                }

            default: // YYYY-MM-DD
                return {
                    formatted: year + "-" + month + "-" + day,
                    month: monthGetter,
                    original: orignal,
                    displayForm: day + " " + monthGetter.full + ", " + year,
                    luxon: luxonDate
                }
        }
    } catch (error) {
        Logger.log("ERROR in CUSTOM DATE FORMAT: ", error);
    }

}
export const confirmSWAL = (title, confirmAction, confirmButtonText = "save", cancelButton = true) => {
    Swal.fire({
        title: title,
        showCancelButton: cancelButton,
        confirmButtonText: confirmButtonText,
    }).then((result) => {
        if (result.isConfirmed) {
            confirmAction();
        }
    })

}

export const orderTypeFinder = (arr, index) => {
    const finder = _.filter(arr, p => _.isEqual(parseInt(p), parseInt(index)))
    // Logger.log("FINDER: ", finder);

    return !!finder.length;

}
export const dateDifference = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    if (date1 > date2) {
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
    } else {
        return -Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
    }
}
export const dateDifferenceInWeeks = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTimeInMilliseconds = Math.abs(date2 - date1);
    Logger.log("=== DATE 1 : {{" + date1 + "}}: | DATE2 : {{" + date2 + "}}", diffTimeInMilliseconds);
    if (date1 > date2) {
        return Math.floor(diffTimeInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    } else {
        return -Math.floor(diffTimeInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    }
}
export const getNewDate = (date, format = undefined, checkByTodayDate = false) => {
    let dateToReturn = "";
    try {
        if (date) {
            dateToReturn = DateTime.fromISO(date).setZone("America/New_York");
            if (!dateToReturn.isValid) {
                dateToReturn = DateTime.fromHTTP(date).setZone("America/New_York");
            }
        } else {
            dateToReturn = DateTime.now().setZone("America/New_York");
        }

        if (checkByTodayDate && dateToReturn < DateTime.now()) {
            dateToReturn = DateTime.now().setZone("America/New_York");
        }

        if (format) {
            return dateToReturn.toFormat(format);
        } else {
            return dateToReturn;
        }
    } catch (error) {
        Logger.error("DATE ERROR IN GET NEW DATE FORMAT: ", error);
        return date;
    }
}

export const getAmericanDateFormatter = (date, format) => {
    try {
        const parsedDate = new Date(date).toISOString();
        return getNewDate(parsedDate, format ? format : "MM/dd/y");
    } catch (error) {
        Logger.error("DATE ERROR IN AMERICAN DATE FORMAT: ", error);
        return date;
    }

}

export const getDateFormatForBackend = (date) => {
    const dateFormatted = date.getFullYear() + "-" + date.getMonth().toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " 00:00:00";
    Logger.log("DATE: ", dateFormatted)
    return dateFormatted;
}
export const compareTwoDates = (dateOne, dateTwo, comparison = "EQ") => {
    const dateOneParsed = getNewDate(new Date(dateOne).toISOString());
    const dateTwoParsed = getNewDate(new Date(dateTwo).toISOString());

    if (comparison === "EQ") {
        return dateOneParsed.startOf("day").ts === dateTwoParsed.startOf("day").ts;
    }

}
export const getTrackerDO = (previousTrack, newEvent, page, data, cb) => {
    const tracker = _.clone(previousTrack);

    tracker.push({
        event: newEvent,
        // data: data,
        page: page,
        timestamp: getNewDate()
    });

    // Logger.log("TRACKER: ", tracker);
    return tracker;

}
export const encryptionHeadersForProductApi = (text = 'authorized') => {

    const key = crypto.randomBytes(32);

    const iv = crypto.randomBytes(16);

    // Creating Cipheriv with its parameter
    let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);

    // Updating text
    let encrypted = cipher.update(text);

    // Using concatenation
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    // Returning iv and encrypted data
    return {
        "X-Authkey": key.toString('hex'),
        "X-Candy": iv.toString('hex'),
        "X-Authorization": encrypted.toString('hex')
    };
}

export const excludeSplitFromId = (productId) => {
    Logger.log("Product Id: ", productId);
    if ((typeof productId === 'string' || productId instanceof String) && productId.includes("_split")) {
        return productId.split("_split")[0];
    }
    return productId;
}

export const arraysAreSimilar = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const getSizesGreaterThanZero = (qty) => {
    return Object.entries(qty)
        .filter(([size, quantity]) => size !== 'totalQuantity' && quantity > 0)
        .map(([size]) => size);
}

export const validateEmbroideryDetails = (cart) => {
    Logger.log("Cart to Validate Embroidery ===> ", cart);
    let hasEmbroideryAvailable = cart.some(item => item.embroideryAvailable);
    Logger.log("Has Embroidery Enable ===> ", hasEmbroideryAvailable);
    if (!hasEmbroideryAvailable) {
        return true;
    }

    return cart.every(item => {
        Logger.log("Has Embroidery Enable Item ===> ", item);
        if (item.embroideryAvailable && item.shouldAddEmbroidery) {
            return item.embroideryDetails.length > 0;
        }
        return true;
    });
}

export const checkExceedsMaxQty = (availability, cartItem) => {
    // Logger.log("==== Availability : ", availability);
    // Logger.log("==== Cart Item : ", cartItem);
    // Logger.log("==== Cart Item Quantity : ", cartItem.qty);

    if (cartItem) {
        for (const [key, value] of Object.entries(cartItem.qty)) {
            // Logger.log(`Key: ${key}, Value: ${value}`);
            const findSizeAvailability = _.find(availability, p => p.size.sizeName == key);
            if (findSizeAvailability && findSizeAvailability.hasOwnProperty("alternativeQtyAndDeliveryDate")
                && findSizeAvailability.alternativeQtyAndDeliveryDate) {
                // Logger.log("=== Availability In Loop : ", findSizeAvailability);
                if (value > findSizeAvailability.maxQtySelection) {
                    return true;
                }
            }
        }
    }


    return false;
}
export const checkExceedsMaxQtyInWholeCart = (cart) => {
    // Logger.log("==== Availability : ", availability);
    // Logger.log("==== Cart Item : ", cartItem);
    // Logger.log("==== Cart Item Quantity : ", cartItem.qty);
    // Logger.log("====> Cart : ", cart);
    let returnFlag = [];
    _.forEach(cart, (cartItem, key) => {

        const availability = cartItem.itemAvailability;

        if(!cartItem.hasOwnProperty("userDefinedDate") && !cartItem.userDefinedDate) {

            // Logger.log("====> Cart Item : ", cartItem);
            // Logger.log("====> Availability : ", availability);
            // Logger.log("====> Cart Item Quantity : ", cartItem.qty);
            for (const [key, value] of Object.entries(cartItem.qty)) {
                // Logger.log(`Key: ${key}, Value: ${value}`);
                const findSizeAvailability = _.find(availability, p => p.size.sizeName == key);

                // Logger.log("====> Find Availability : ", findSizeAvailability);
                if (findSizeAvailability && findSizeAvailability.hasOwnProperty("alternativeQtyAndDeliveryDate")
                    && findSizeAvailability.alternativeQtyAndDeliveryDate) {
                    // Logger.log("=== Availability In Loop : ", findSizeAvailability);
                    if (value > findSizeAvailability.maxQtySelection) {
                        // Logger.log("====> Item Exceeded : ", cartItem);

                        if (!areDatesEqual(new Date(cartItem.changeableDeliveryDate.original),
                            new Date(findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate.original))) {
                            returnFlag.push({
                                item: cartItem,
                                date: findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate
                            });
                        }
                    } else if (value) {
                        if (areDatesEqual(new Date(cartItem.changeableDeliveryDate.original),
                            new Date(findSizeAvailability.alternativeQtyAndDeliveryDate.wipDate.original))) {
                            returnFlag.push({
                                item: cartItem,
                                date: findSizeAvailability.alternativeQtyAndDeliveryDate.originalDeliveryDate
                            });
                        }
                    }
                }
            }
        }
    });
    return returnFlag;
}

export const areDatesEqual = (date1, date2) => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export const getAvailableSizes = (sizes, items)  => {
    const availableSizes = new Set();

    items.forEach(item => {
        for (const [size, quantity] of Object.entries(item.qty)) {
            if (sizes.includes(size) && quantity > 0) {
                availableSizes.add(size);
            }
        }
    });

    return Array.from(availableSizes);
}
