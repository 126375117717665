// Library
import React, {useState, useEffect} from 'react';
import {InputGroup, InputGroupAddon, Button, Input} from 'reactstrap';
import {CART_MAX_QTY} from "../../Utils/CommonConstants";

// Logger
import Logger from "../../Utils/Logger";


const PlusMinusInput = (props) => {
    const {
        incrementer,
        decrementer,
        value,
        id,
        name,
        product,
        size,
        sizeNumber,
        inputChanger
    } = props;

    // Logger.log("Props for Input : ", props);
    const [inputValue, setInputValue] = useState(value);


    useEffect(() => {
        setInputValue(value);
    }, [value]);

    // const doDecrement = (id, product, size) => {
    //     document.getElementById(id).removeEventListener('change',() => {Logger.log("SUCCESSFULLY REMOVED EVENT")});
    //     decrementer(id, product, size);
    // }

    const valueChanger = (id, product, size, value) => {
        const valueChecker = /^\d*[1-9]\d*$/;
        if(valueChecker.test(value)) {
            document.getElementById(id).addEventListener('change',() => {Logger.log("SUCCESSFULLY ADDED EVENT")});
            // Logger.log("INPUT CHANGER", value);
            setInputValue(value);
            inputChanger(id, product, size, sizeNumber, value);
        }
        // if(product.deliveryGreaterThanTwoMonths) {
        //     if (value > CART_MAX_QTY) {
        //         setInputValue(CART_MAX_QTY);
        //         inputChanger(id, product, size, CART_MAX_QTY);
        //     } else {
        //         setInputValue(value);
        //         inputChanger(id, product, size, value);
        //     }
        // } else {
        //     if(value > product.maxQuantitySelection) {
        //         setInputValue(product.maxQuantitySelection);
        //         inputChanger(id, product, size, product.maxQuantitySelection);
        //     } else {
        //         setInputValue(value);
        //         inputChanger(id, product, size, value);
        //     }
        // }
    }

    const onBlurHandler = () => {
        if(product.deliveryDateInGracePeriod) {
            if (value > CART_MAX_QTY) {
                setInputValue(CART_MAX_QTY);
                inputChanger(id, product, size, sizeNumber, CART_MAX_QTY);
            } else {
                setInputValue(value);
                inputChanger(id, product, size,sizeNumber, value);
            }
        } else {
            if(value > product.maxQuantitySelection) {
                setInputValue(product.maxQuantitySelection);
                inputChanger(id, product, size, sizeNumber,product.maxQuantitySelection);
            } else {
                setInputValue(value);
                inputChanger(id, product, size,sizeNumber, value);
            }
        }
    }

    return (
        <>
            <div style={{maxWidth: "150px", float: "left", margin: 0}}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button tabIndex="-1" style={{width: "30px", height: "30px"}} size={"sm"} color="secondary"
                                onClick={() => decrementer(id, product, size,sizeNumber)}><strong>-</strong></Button>
                    </InputGroupAddon>
                    <Input type={"number"} style={{height: "30px", width: "38px"}} bsSize={"sm"} value={inputValue}
                           id={id} name={name} className={"text-center"}
                           onChange={(e) => valueChanger(id, product, size, e.target.value)}
                           onBlur={(e) => onBlurHandler(e.target.value)}/>
                    <InputGroupAddon addonType="append">
                        <Button tabIndex="-1" style={{width: "30px", height: "30px"}} size={"sm"} color="secondary"
                                onClick={() => incrementer(id, product, size, sizeNumber)}><strong>+</strong></Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </>
    )
}
export default PlusMinusInput;
